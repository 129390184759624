var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'name',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'type',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","filter-option":_vm.$selectFilterOption,"placeholder":""}},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'status',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],attrs:{"button-style":"solid"}},_vm._l((_vm.statusList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"背景图"}},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('a-button',{staticStyle:{"margin-right":"8px"},attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.path ? "已上传" : "上传")+" ")]),(_vm.path)?_c('a',{attrs:{"href":_vm.path,"target":"_blank"}},[_vm._v("预览")]):_vm._e()],1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"备注","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark']),expression:"['remark']"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saveLoading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }